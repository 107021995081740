<template>
  <div>
    <Form :options="formOptions" :existingEmail="true"></Form>
  </div>
</template>
<script>
import moment from 'moment'
import Form from '../../../../src/components/form/Form.vue'
import axios from '@/components/axios'
// import { required } from "vuelidate/lib/validators";
const baseApi = process.env.VUE_APP_APIENDPOINT
export default {
  data () {
    return {
      formOptions: {
        formTitle: `${this.$route.params.id ? 'Edit Users' : 'Add Users'}`,
        submitRouterPath: '/setup/users',
        usersDropdown: true,
        branchDropdown: true,
        designationDropdown: true,
        method: 'post',
        action: 'add',
        gridForm: true,
        getEditValue: `${baseApi}/getusersbyid`,
        url: `${baseApi}/users`,
        inputFields: [
          {
            name: 'company',
            value: 1,
            type: 'hidden'
          },
          {
            name: 'active',
            value: 1,
            type: 'hidden'
          },
          {
            label: 'Name',
            name: 'name',
            value: '',
            type: 'Uname',
            placeholder: 'Enter Name',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Email',
            name: 'email',
            value: '',
            type: 'email',
            placeholder: 'Enter Email Address',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Mobile',
            name: 'mobile',
            value: '',
            type: 'number',
            placeholder: 'Enter Mobile',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Address',
            name: 'address',
            value: '',
            type: 'text',
            placeholder: 'Enter Address',
            class: 'col-md-6'
            // required: true,
          },
          {
            label: 'Area',
            name: 'area',
            value: '',
            type: 'text',
            placeholder: 'Enter Area',
            class: 'col-md-6'
            // required: true,
          },
          {
            label: 'State',
            name: 'state',
            value: '',
            type: 'text',
            placeholder: 'Enter state',
            class: 'col-md-6'
            // required: true,
          },
          {
            label: 'City',
            name: 'city',
            value: '',
            type: 'text',
            placeholder: 'Enter city',
            class: 'col-md-6'
            // required: true,
          },
          {
            label: 'Pin Code',
            name: 'pincode',
            value: '',
            type: 'number',
            placeholder: 'Enter Pincode',
            class: 'col-md-6'
            // required: true,
          },
          {
            label: 'Gender',
            name: 'gender',
            value: '',
            type: 'dropdown',
            class: 'col-md-6',
            required: true,
            responseValue: 'name',
            // inputStyle: "height: 25px; padding: 5px",
            options: [{ name: 'Male' }, { name: 'Female' }]
          },
          {
            label: 'User Name',
            name: 'username',
            value: '',
            type: 'text',
            placeholder: 'Enter username',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Password',
            name: 'password',
            value: '',
            type: 'password',
            placeholder: 'Enter Password',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'joining Date',
            name: 'joiningdate',
            value: moment(new Date()).format('yyyy-MM-DD'),
            type: 'date',
            placeholder: 'Enter joining date',
            class: 'col-md-6'
            // required: true,
          },
          {
            label: 'Resign Date',
            name: 'resigndate',
            value: moment(new Date()).format('yyyy-MM-DD'),
            type: 'date',
            placeholder: 'Enter resign date',
            class: 'col-md-6'
            // required: true,
          },
          {
            label: 'Reporting Authority',
            name: 'parent',
            value: '',
            type: 'dropdown',
            class: 'col-md-6',
            dropdownname: 'parent',
            url: 'getUsers',
            responseValue: 'id',
            // required: true,
            // inputStyle: "height: 25px; padding: 5px",
            options: []
          },
          {
            label: 'Designation',
            name: 'designation',
            value: '',
            type: 'dropdown',
            class: 'col-md-6',
            dropdownname: 'designation',
            url: 'getPosition',
            responseValue: 'id',
            // required: true,
            // inputStyle: "height: 25px; padding: 5px",
            options: []
          },
          {
            label: 'Branch',
            name: 'branch',
            value: '',
            type: 'dropdown',
            class: 'col-md-6',
            dropdownname: 'branch',
            responseValue: 'id',
            url: 'branch',
            // required: true,
            // inputStyle: "height: 25px; padding: 5px",
            options: []
          }
        ]
      }
    }
  },
  components: {
    Form
  },
  beforeMount () {
    const role = JSON.parse(localStorage.getItem('userData')).role
    if (role !== 'admin') {
      this.$router.push('/')
    }
    const accessToken = localStorage.getItem('accessToken')
    const baseApi = process.env.VUE_APP_APIENDPOINT
    this.formOptions.inputFields.map(async (item) => {
      if (item.type == 'dropdown' && item.url) {
        const requestOptionsUsers = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
          },
          url: `${baseApi}/${item.url}`
        }

        await axios(requestOptionsUsers)
          .then((response) => {
            response.data.data.data
              ? (item.options = response.data.data.data)
              : (item.options = response.data.data)
          })
          .catch((error) => console.log(error, 'error'))
      }
    })
  },
  mounted () {}
}
</script>
